<template>
  <ClientOnly>
    <!-- Full navbar content -->
    <v-app-bar :elevation="0">
      <v-container class="py-0">
        <v-row>
          <v-col class="d-flex align-center py-0">
            <v-app-bar-title>
              <nuxt-link to="/">
                <v-img
                  class="me-2 logo"
                  width="200"
                  height="60"
                  src="/logo-gotong-royong-indonesia.webp"
                  alt="Gotong Royong Indonesia Logo"
                  eager
                />
              </nuxt-link>
            </v-app-bar-title>

            <!-- Desktop Navigation Menu -->
            <ul class="navbar-nav pl-0 d-md-flex d-none flex-row">
              <li class="nav-item me-2">
                <div
                  class="nav-link"
                  @click.prevent="navigateToSection('/', 'home')"
                >
                  <p class="mb-0">Beranda</p>
                </div>
              </li>
              <li class="nav-item me-2">
                <nuxt-link
                  class="nav-link"
                  @click.prevent="navigateToSection('/', 'tentang-kami')"
                  to="/"
                >
                  <p class="mb-0">Tentang kami</p>
                </nuxt-link>
              </li>
              <li class="nav-item me-2">
                <nuxt-link
                  class="nav-link"
                  @click.prevent="navigateToSection('/', 'kelebihan-kami')"
                  to="/"
                >
                  <p class="mb-0">Kelebihan kami</p>
                </nuxt-link>
              </li>
              <li class="nav-item me-2">
                <a
                  class="nav-link"
                  href="https://blog.gotongroyong.org/category/kisah-kami/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p class="mb-0">Kisah Kami</p>
                </a>
              </li>
              <li class="nav-item me-2">
                <nuxt-link class="nav-link" to="/activities">
                  <p class="mb-0">Cari aktivitas</p>
                </nuxt-link>
              </li>
            </ul>

            <!-- Auth buttons -->
            <div
              v-if="!$auth.isAuthenticated"
              class="d-flex align-center ml-auto ml-md-4"
            >
              <v-btn
                class="me-2 text-capitalize font-weight-bold"
                color="primary"
                dark
                size="small"
                to="/auth?type=signup"
                variant="outlined"
              >
                Daftar
              </v-btn>
              <v-btn
                to="/auth"
                color="primary"
                variant="flat"
                size="small"
                class="text-capitalize font-weight-bold"
              >
                Masuk
              </v-btn>
            </div>
            <section v-else class="ml-auto ml-md-4">
              <UserProfileMenuButton @logout="onLogout" />
            </section>

            <v-app-bar-nav-icon
              class="d-block ml-2 d-md-none"
              variant="text"
              @click.stop="onToggleDrawer"
              aria-label="Menu navigasi"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <!-- Mobile navigation drawer -->
    <v-navigation-drawer
      v-model="isShowDrawer"
      temporary
      location="right"
      width="280"
    >
      <v-list color="primary" class="px-4">
        <v-list-subheader class="font-weight-bold text-caption">
          Tentang Platform
        </v-list-subheader>
        <v-list-item @click="navigateSidebarToSection('/', 'tentang-kami')">
          <v-list-item-title class="text-grey-darken-1">
            Tentang kami
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="navigateSidebarToSection('/', 'kelebihan-kami')">
          <v-list-item-title class="text-grey-darken-1">
            Kelebihan kami
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          href="https://blog.gotongroyong.org/category/kisah-kami/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-list-item-title class="text-grey-darken-1">
            Kisah kami
          </v-list-item-title>
        </v-list-item>
        <v-list-item to="/activities" @click="isShowDrawer = false">
          <v-list-item-title class="text-grey-darken-1">
            Cari aktivitas
          </v-list-item-title>
        </v-list-item>
        <v-divider />

        <v-list v-if="$auth.isAuthenticated" color="primary">
          <template v-if="$auth.isSuperAdmin">
            <v-list-subheader class="font-weight-bold text-caption">
              Manajemen
            </v-list-subheader>
            <v-list-item
              to="/dashboard/admin/users"
              @click="isShowDrawer = false"
            >
              <v-list-item-title class="text-grey-darken-1">
                Kelola Pengguna
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/dashboard/admin/activities"
              @click="isShowDrawer = false"
            >
              <v-list-item-title class="text-grey-darken-1">
                Kelola Aktivitas
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-subheader class="font-weight-bold text-caption">
              Akun Pengguna
            </v-list-subheader>
            <v-list-item to="/dashboard/profile" @click="isShowDrawer = false">
              <v-list-item-title class="text-grey-darken-1">
                Profil
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/dashboard/my-activities"
              @click="isShowDrawer = false"
            >
              <v-list-item-title class="text-grey-darken-1">
                Aktivitas saya
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              to="/dashboard/activities"
              @click="isShowDrawer = false"
            >
              <v-list-item-title class="text-grey-darken-1">
                Kelola aktivitas
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-divider />
          <v-list-subheader class="font-weight-bold text-caption">
            Pengaturan
          </v-list-subheader>
          <v-list-item
            to="/dashboard/change-password"
            @click="isShowDrawer = false"
          >
            <v-list-item-title class="text-grey-darken-1">
              Ganti kata sandi
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-list>

      <template #append>
        <div class="pa-2">
          <v-btn
            v-if="!$auth.isAuthenticated"
            color="primary"
            class="text-capitalize font-weight-bold"
            block
            to="/auth"
            @click="isShowDrawer = false"
          >
            Masuk / Daftar
          </v-btn>
          <v-btn
            v-else
            color="primary"
            prepend-icon="mdi-logout"
            block
            @click="onLogout"
          >
            Keluar
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- Fallback for SSR -->
    <template #fallback>
      <v-app-bar :elevation="0">
        <v-container class="py-0">
          <v-row>
            <v-col class="d-flex align-center py-0">
              <v-app-bar-title>
                <nuxt-link to="/">
                  <v-img
                    class="me-2 logo"
                    width="200"
                    height="60"
                    src="/logo-gotong-royong-indonesia.webp"
                    alt="Gotong Royong Indonesia Logo"
                    eager
                  />
                </nuxt-link>
              </v-app-bar-title>
              <!-- Minimal content for SSR -->
              <div class="d-flex align-center ml-auto ml-md-4">
                <v-btn
                  class="me-2 text-capitalize font-weight-bold"
                  color="primary"
                  dark
                  size="small"
                  to="/auth?type=signup"
                  variant="outlined"
                >
                  Daftar
                </v-btn>
                <v-btn
                  to="/auth"
                  color="primary"
                  variant="flat"
                  size="small"
                  class="text-capitalize font-weight-bold"
                >
                  Masuk
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-app-bar>
    </template>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { useDisplay } from "vuetify";
import { useAnchorScroll } from "~/common/composables/useAnchorScroll";
import UserProfileMenuButton from "./UserProfileMenuButton.vue";

const { navigateToSection } = useAnchorScroll();

const { mdAndUp } = useDisplay();

const isShowDrawer = ref(false);
const onToggleDrawer = () => {
  isShowDrawer.value = !isShowDrawer.value;
};

const navigateSidebarToSection = (path: string, section: string) => {
  navigateToSection(path, section);
  isShowDrawer.value = false;
};

watchEffect(() => {
  if (mdAndUp.value) {
    isShowDrawer.value = false;
  }
});

const { $auth } = useNuxtApp();

const onLogout = () => {
  $auth.signOut();
  isShowDrawer.value = false;
};
</script>

<style>
ul.navbar-nav {
  list-style: none;
  li.nav-item {
    .nav-link {
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      color: #2e7d32 !important;
      display: block;
      padding: 0px 8px;
      text-decoration: none;
      letter-spacing: normal;
      &:hover {
        color: #2e7d32 !important;
      }
    }
  }
}
</style>
